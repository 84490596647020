import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeaderClass } from "../../redux/layoutSlice";
import SearchFilterMenu from "../../components/searchByAmakenMap/SearchFilterMenu";
import PropertyList from "../../components/searchByAmakenMap/PropertyList";
import MapSearchDemo from "../../components/searchByAmakenMap/MapSearchDemo";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { constructUrl, createSearchPayload, propertyListApi, propertyListMapApi, removeSearchParams } from "../../services/propertyService";
import { nameBasedRoutes } from "../../router/public";
// import LoaderMu from "../../components/common/LoaderMu";
import { useTranslation } from "react-i18next";
import ReactGA4 from "react-ga4"
import SeoComponent from "../../components/seo/SeoComponent";
import LoaderMu from "../../components/common/LoaderMu";

export const Searchbyamakenmap = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const otherParams = new URLSearchParams([...params].filter(([key]) => key !== 'page' && key !== 'sort')).toString();
  const paramsObj = Object.fromEntries(params);

  const payload = useMemo(() => createSearchPayload(paramsObj), [paramsObj]);
  const currentPage = parseInt(params.get('page'), 10) || 1;
  const sortField = params.get('sort') || 'listingDate,DESC';
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false);
  const [propertyGridData, setPropertyGridData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [propertyMapList, setPropertyMapList] = useState(null);
  const [searchText] = useState('');
  const [placesList, setPlacesList] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState('');
  const [polylinesData, setPolylinesData] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(paramsObj?.formattedAddress);
  const [mapBounds, setMapBounds] = useState({})
  const pageSize = 24;

  useEffect(() => {
    dispatch(setHeaderClass({ className: 'container-fluid' }));
  }, [dispatch]);

  const fetchPropertyData = () => {
    setLoading(true);
    let formData = { isMapView: false, ...payload };

    if (!polylinesData.length && selectedPlace) {
      formData = { ...formData, location: selectedPlace };
    } else if (polylinesData.length) {
      formData = { ...formData, polyline: polylinesData };
    }

    if (mapBounds) {
      formData = { ...formData, mapbound: mapBounds }
    }

    let sortString = 'sort=isFeatured,DESC';
    if (sortField !== 'UNSORTED') {
      const sortParts = sortField.split(': ').join(',').split(',');
      sortString += sortParts.map((part, index) => index % 2 === 0 ? `&sort=${part}` : `,${part}`).join('');
    }

    const queryParams = {
      size: pageSize,
      page: currentPage - 1
    };

    ReactGA4.event({
      category: 'search_property',
      action: 'search',
      label: 'Property List',
      value: Date.now(),
    });

    propertyListApi(formData, queryParams, sortString)
      .then(response => {
        setLoading(false);
        setPropertyGridData(response?.data || []);
        setTotalRowCount(response?.headers["x-total-count"] || 0);

        if (formData?.save) {
          navigate(removeSearchParams(nameBasedRoutes?.searchByMap?.path, 'save'), { replace: true });
        }

        if (response?.headers["x-total-count"] < pageSize) {
          navigate(constructUrl(nameBasedRoutes?.searchByMap?.path, { key: 'page', value: 1 }), { replace: true });
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error?.response?.data?.error_description || error?.response?.data?.message || error.message);
      });
  };

  const fetchPropertyMapData = () => {
    setLoading(true);
    let formData = { isMapView: true, ...payload };

    if (!polylinesData.length && selectedPlace) {
      formData = { ...formData, location: selectedPlace };
    } else if (polylinesData.length) {
      formData = { ...formData, polyline: polylinesData };
    }
    if (mapBounds) {
      formData = { ...formData, mapbound: mapBounds }
    }
    const mapParams = {
      size: 240,
      page: 0,
      sort: 'isFeatured,desc'
    };

    ReactGA4.event({
      category: 'search_property',
      action: 'search',
      label: 'Property Map',
      value: Date.now(),
    });

    propertyListMapApi(formData, mapParams)
      .then(response => {
        setLoading(false);
        setPropertyMapList(response?.data || []);
      })
      .catch(error => {
        setLoading(false);
        toast.error(error?.response?.data?.error_description || error?.response?.data?.message || error.message);
      });
  };

  useEffect(() => {
    if (paramsObj?.formattedAddress) {
      setSelectedAddress(paramsObj.formattedAddress);
      fetchPropertyData();
    } else {
      setSelectedAddress('');
      fetchPropertyData();
    }
  }, [location?.search, selectedPlace, polylinesData, i18n?.language, mapBounds]);

  useEffect(() => {
    if (paramsObj?.formattedAddress) {
      setSelectedAddress(paramsObj.formattedAddress);
      fetchPropertyMapData();
    } else {
      setSelectedAddress('');
      fetchPropertyMapData();
    }
  }, [otherParams, selectedPlace, polylinesData, mapBounds]);


  return (
    <div className="body_content">
      {/* <LoaderMu loading={loading} /> */}
      {/* PROPERTY FILTER MERNU */}
      <SeoComponent
        title={t("SEARCH_BY_AMAKEN_PAGE_TITLE")}
        meta_title={t("SEARCH_BY_AMAKEN_META_TITLE")}
        description={t("SEARCH_BY_AMAKEN_META_DESCRIPTION")}
        keywords={t("SEARCH_BY_AMAKEN_KEYWORDS")}
        canonical={process.env.REACT_APP_WEBSITE_WEB_URL + nameBasedRoutes?.searchByMap?.path}
      />
      <LoaderMu loading={loading} />
      <SearchFilterMenu
        places={placesList}
        setSelectedPlace={setSelectedPlace}
        selectedPlace={selectedPlace}
        selectedAddress={selectedAddress}
      />
      <section className="p-0 bgc-f7">
        <div className="container-fluid">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <MapSearchDemo //MAP COMPONENT 
              areaSearchText={searchText}
              setPlaces={setPlacesList}
              selectedPlaceId={selectedPlace?.place_id}
              setData={setPolylinesData}
              propertyList={propertyMapList}
              setMapBounds={setMapBounds}
              mapBounds={mapBounds}
            />
            <PropertyList selectedPlace={selectedPlace} //PROPERTY LIST
              propertyGridData={propertyGridData}
              polylinesData={polylinesData}
              totalRowCount={totalRowCount}
              pageSize={pageSize}
              loading={loading}
            />
          </div>
        </div>
      </section>
    </div>
  );
};  
