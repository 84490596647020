export const englishLang = {
    "SELECT": "Select",
    "SUBMIT": "Submit",
    "USERNAME": "Username",
    "PASSWORD": "Password",
    "CHOOSE PASSWORD": "Choose Password",
    "CONFIRM PASSWORD": "Confirm Password",
    "FIRST NAME": "First  Name",
    "LAST NAME": "Last Name",
    "EMAIL": "Email",
    "EMAIL ADDRESS": "Email Address",
    "AGENCY": "Agency",
    "AGENT": "Agent",
    "ADDRESS": "Address",
    "YOUR ADDRESS": "Your Address",
    "FACEBOOK URL": "Facebook URL",
    "INSTAGRAM URL": "Instagram URL",
    "LINKEDIN URL": "Linkedin URL",
    "MANAGE ACCOUNT": "Manage Account",
    "MY PROFILE (AGENT)": "My Profile (Agent)",
    "MY PROFILE (AGENCY)": "My Profile (Agency)",
    "MENU": "Menu",
    "NEED SUPPORT?": "Need Support?",
    "DISCOVER JORDAN CITIES": "Discover Cities in Jordan",
    "POPULAR SEARCH": "Popular Searches",
    "QUICK LINKS": "Quick Links",
    "ABOUT AMAKEN": "About Amaken",
    "OUR SERVICES": "Our Services",
    "TERMS & CONDITION": "Terms & Conditions",
    "PRIVACY POLICY": "Privacy Policy",
    "FAQS": "FAQs",
    "DOWNLOAD AMAKEN APP": "Download Amaken App",
    "DOWNLOAD ON THE": "Download on the",
    "APPLE STORE": "App Store",
    "GET IN ON": "Get it on",
    "GOOGLE PLAY": "Google Play",
    "FOLLOW US": "Follow Us",
    "AMAKEN - ALL RIGHTS RESERVED": "Amaken - All Rights Reserved",
    "PRIVACY": "Privacy",
    "TERMS": "Terms",
    "SITEMAP": "Sitemap",
    "FIND YOUR DREAM PROPERTY": "Find Your Dream Property ",
    "AT AMAKEN... ": "at Amaken...",
    "YOUR IDEAL REAL ESTATE MARKETPLACE IN JORDAN": "Your Ideal Real Estate Marketplace in Jordan",
    "PROPERTIES BY CITIES": "Properties by Cities",
    "AMAKEN": "Amaken",
    "PROPERTIES FOR YOU": "Properties for You",
    "BASED ON YOUR LATEST SEARCH PROCESS": "Based on Your Latest Searches",
    "SEE MORE RESULTS": "See More Results",
    "PROPERTIES": "Properties",
    "SEE ALL PROPERTIES": "See All Properties",
    "FEATURED AGENCIES": "Featured Agencies at",
    "A REAL ESTATE AGENCY?": "A Real Estate Agency?",
    "START LISTING": "Start Listing & Your Properties With Amaken",
    "IT'S SIMPLE AND EASY, CLICK BELOW AND FOLLOW THE INSTRUCTIONS.": "It's simple and easy. Click below and follow the instructions.",
    "FORGET PASSWORD": "Forgot Password",
    "ENTER EMAIL": "Enter Email",
    "ENTER YOUR EMAIL ADDRESS TO RESET YOUR PASSWORD.": "Enter your email address",
    "GET RESET LINK": "Send Reset Link",
    "BACK": "Back",
    "RESET PASSWORD": "Reset Password",
    "ENTER PASSWORD": "Enter Password",
    "ENTER CONFIRM PASSWORD": "Enter Confirm Password",
    "AGENCY AVAILABLE": " Agency Available",
    "ABOUT": "About",
    "REAL ESTATE": "Real Estate",
    "CONTACT FORM": "Contact Form",
    "AGENCY INFORMATION": "Agency Information",
    "OFFICE": "Office",
    "MOBILE": "Mobile",
    "WEBSITE": "Website",
    "USER PROFILE": "User Profile",
    "UPLOAD PROFILE PICTURE": "Upload Profile Picture",
    "JPEG OR PNG FORMAT": "JPEG or PNG Format",
    "UPDATE PROFILE": "Update Profile",
    "CODE": "Code",
    "PHONE NUMBER": "Phone Number",
    "YOUR PHONE NUMBER": "Your Phone Number",
    "PREFERRED LANGUAGE": "Preferred Language",
    "MAIN USAGE OF THE PLATFORM": "Main Usage Of The Platform",
    "NOTHING SELECTED": "Nothing Selected",
    "AREAS OF INTEREST": "Areas Of Interest",
    "IS THIS YOUR FIRST BUY OR RENT?": "Is This Your First Buy or Rent?",
    "WHEN IS THE TIMELINE TO BUY OR RENT?": "When Is the Timeline to Buy or Rent?",

    "CHANGE PASSWORD": "Change Password",
    "OLD PASSWORD": "Old Password",
    "NEW PASSWORD": "New Password",
    "CONFIRM NEW PASSWORD": "Confirm New Password",
    "DELETE ACCOUNT": "Delete Account",
    "DELETE THE IMAGE": "Delete the Image",
    "ARE YOU SURE TO DELETE PROFILE PICTURE?": "Are You Sure You Want To Delete Profile Picture?",
    "YES": "Yes",
    "NO": "No",
    "BEFORE YOU PROCEED WITH ACCOUNT DELETING, PLEASE TAKE A MOMENT TO CONSIDER THAT ALL YOUR DATA, INCLUDING PROFILE INFORMATION, WILL BE PERMANENTLY DELETED AND CANNOT BE RECOVERED. IF YOU CHANGE YOUR MIND LATER, YOU WILL NEED TO CREATE A NEW ACCOUNT FROM SCRATCH.": "Before You Proceed with Account Deletion, Please Take a Moment to Consider That All Your Data, Including Profile Information, Will Be Permanently Deleted and Cannot Be Recovered. If You Change Your Mind Later, You Will Need to Create a New Account from Scratch.",
    "WELCOME": "Welcome",
    "MY FAVORITES": "Favorites",
    "MY PROFILE (USER)": "My Profile (User)",
    "LOGOUT": "Logout",
    "HOME": "Home",
    "SEARCH BY AMAKEN MAP": "Search by Amaken Map",
    "AGENCIES": "Agencies",
    "PAGES": "About Amaken",
    "ABOUT US": "Who we are",
    "FAQ": "FAQs",
    "AGENCIES SIGN IN": "Agencies Sign In",
    "AGENCIES SIGN UP": "Agencies Sign Up",
    "AGENCIES LOGIN": "Agencies Sign In",
    "SIGN IN": "Sign In",
    "SIGN UP": "Sign Up",
    "WELCOME TO AMAKEN": "Welcome to Amaken",
    "REMEMBER ME": "Remember Me",
    "FORGET PASSWORD?": "Forget Password?",
    "OR": "Or",
    "WITH GOOGLE": "With Google",
    "WITH FACEBOOK": "With Facebook",
    "WITH APPLE": "With Apple",
    "NEED HELP? TALK TO OUR EXPERT": "Need Help? Talk to Our Expert",
    "TALK TO OUR EXPERTS OR BROWSE THROUGH MORE PROPERTIES": "Talk to Our Experts or Browse Through More Properties",
    "CONTACT US": "Contact Us",
    "FREQUENTLY ASKED QUESTIONS": "Frequently Asked Questions",
    "NO FAQ AVAILABLE": "No FAQs Available",
    "I AGREE": "I Agree",
    "TERMS AND CONDITIONS": "Terms and Conditions",
    "CREATE ACCOUNT": "Create Account",
    "ADVANCE SEARCH": "Advanced Search",
    "SEARCH KEYWORD": "Search Keyword",
    "SEARCH": "Search",
    "PRICE RANGE": "Price Range",
    "PROPERTY CATEGORY": "Property Category",
    "TYPE": "Type",
    "LOCATION": "Location",
    "BEDROOMS": "Bedrooms",
    "ANY": "Any",
    "BATHROOMS": "Bathrooms",
    "MIN.": "Min.",
    "MAX.": "Max.",
    "SQUARE METER": "Sq. Meter",
    "PROPERTY ID": "Amaken Ref.",
    "HAVE 3D TOUR": "Have 3D Tour",
    "AGENCY/AGENT": "Agency/Agent",
    "OTHER FEATURES": "Other Features",
    "RESET ALL FILTERS": "Reset All Filters",
    "JOD": "JOD",
    "SQ.M.": "Sq. Meter",
    "SEE ALL": "See All",
    "OVERVIEW": "Overview",
    "BEDROOM": "Bedroom",
    "BATH": "Bath",
    "FURNISHED": "Furnished",
    "PROPERTY TYPE": "Property Type",
    "BASEMENT": "Basement",
    "GROUND FLOOR": "Ground Floor",
    "TH FLOOR": "th Floor",
    "YEAR BUILT": "Year Built",
    "FOR RENT": "To Rent",
    "FOR BUY": "To Buy",
    "ALL": "All",
    "PROPERTY DESCRIPTION": "Property Description",
    "SHOW LESS": "Show Less",
    "SHOW MORE": "Show More",
    "PROPERTY DETAILS": "Property Details",
    "PRICE": "Price",
    "PROPERTY STATUS": "Purpose",
    "3D TOUR": "3D Tour",
    "DIRECTORATE": "Directorate",
    "COUNTRY": "Country",
    "JORDAN": "Jordan",
    "GOVERNORATE": "Governorate",
    "VILLAGE": "Village",
    "VIDEO": "Video",
    "NA": "NA",
    "FEATURES": "Features",
    "AMENITIES": "Amenities",
    "NEARBY SIMILARS": "Similar Properties",
    "GET MORE INFORMATION": "Get More Information",
    "VIEW AGENCY": "Agency Profile",
    "AGENT PAGE": "Agent Page",
    "VIEW DETAILS": "View Details",
    "SCHEDULE A TOUR": "Schedule a Tour",
    "CHOOSE YOUR PREFERRED DAY": "Choose Your Preferred Day",
    "TIME": "Time",
    "ENTER VALID DATE TIME": "Enter Valid Date and Time",
    "NAME": "Name",
    "PHONE": "Phone",
    "MESSAGE": "Message",
    "TERMS OF USE": "Terms of Use",
    "SUBMIT A TOUR REQUEST": "Submit a Tour Request",
    "DO YOU HAVE ANY PROBLEMS?": "Do You Have Any Problems?",
    "REPORT THIS PROPERTY": "Report This Property",
    "ENTER YOUR NAME": "Enter Your Name",
    "ENTER YOUR EMAIL": "Enter Your Email",
    "ENTER YOUR MESSAGE": "Enter Your Message",
    "SEND REPORT": "Send Report",
    "SELECT A REASON": "Select a Reason",
    "NOT LOGGED IN": "Not Signed In",
    "PLEASE LOG IN TO MARK THIS AS A FAVORITE.": "Please sign in to add to Favorites.",
    "LOGIN NOW": "Sign In Now",
   
    "STATUS": "Status",
    "PURPOSE": "Purpose",
    "DONE": "Done",
    "BEDS": "Beds",
    "BATHS": "Baths",
    "ADVANCED": "Advanced",
    "SHOWING": "Showing",
    "RESULTS": "Results",
    "SORT BY": "Sort By",
    "NEWEST": "Newest",
    "PRICE LOW": "Lowest Price",
    "PRICE HIGH": "Highest Price",
    "OF": "Of",

    "FEATURED": "Featured",
    "FOR": "For",
    "BED": "Bed",

    "DRAW": "Draw",
    "APPLY": "Apply",
    "CANCEL": "Cancel",
    "REMOVE": "Remove",
    "NO PROPERTIES FOUND": "No Properties Found",
    "SHOW ALL TYPES": "Show All Types",
    "BUY": "Buy",
    "RENT": "Rent",
    "SAVE AND APPLY": "Save and Apply",
    "AGENTS": "Agents",
    "LISTING": "Listing",
    "SHOW ALL PROPERTIES": "Show All Properties",
    "AGENT INFORMATION": "Agent Information",
    "WE'RE ON A MISSION TO CHANGE": "We're on a Mission to Change",
    "THE VIEW OF ONLINE REAL ESTATE MARKETPLACE FIELD IN JORDAN.": "The View of the Online Real Estate Marketplace in Jordan",
    "IT DOESN’T MATTER HOW ORGANIZED YOU ARE — A SURPLUS OF TOYS WILL ALWAYS ENSURE YOUR HOUSE IS A MESS WAITING TO HAPPEN. FORTUNATELY, GETTING KIDS ON BOARD WITH THE IDEA OF DITCHING THEIR STUFF IS A LOT EASIER THAN IT SOUNDS.": "Real estate is an amazing experience — and for most people, it’s the biggest transaction they’ll ever make. Amaken is here to help people get their exact property, by connecting buyers, investors, landlords, sellers, tenants and brokers to facilitate various real estate requirements in a seamless and user-friendly manner.",
    "MAECENAS QUIS VIVERRA METUS, ET EFFICITUR LIGULA. NAM CONGUE AUGUE ET EX CONGUE, SED LUCTUS LECTUS CONGUE. INTEGER CONVALLIS CONDIMENTUM SEM. DUIS ELEMENTUM TORTOR EGET CONDIMENTUM TEMPOR. PRESENT SOLICITUDIN LECTUS UT PHARETRA PULVINAR.": "Buying and selling real estate is a difficult process to navigate, because it is fragmented and complex. Your journey to buy a new property includes many steps — research, shop, tour, appraise, inspect, negotiate an offer. Amaken, solves these real pain points by providing seamless solutions. With new functionality and features continuously improving the app and site, as well as connections to great agent partners, Amaken offers the best experience for renters, buyers and sellers.",
    "MORE THAN": "More Than",
    "AMAZING PROPERTY": "Amazing Property",
    "AND": "And",
    "THOUSANDS": "Thousands",
    "OF HAPPY CUSTOMERS": "Of Happy Customers",
    "ENTER 4 DIGIT OTP RECEIVED ON YOUR PHONE": "Enter verification code sent to your number ",
    "ENTER OTP": "Enter verification code",
    "SEND OTP": "Send OTP",
    "COMPARE PROPERTIES": "Compare Properties",
    "COMPARE": "Compare",
    "REMOVE ALL": "Remove All",
    "PLEASE LOG IN TO MARK THIS AS A FAVORITE": "Please Sign In to Mark This as a Favorite.",
    "PROFILE UPDATED!": "Profile Updated!",
    "EDIT": "Edit",
    "ADD": "Add",
    "VERIFY": "Verify",
    "VERIFY EMAIL": "Verify Email",
    "ADDED": "Added",
    "YOUR EMAIL IS NOT VERIFIED. PLEASE VERIFY YOUR EMAIL ADDRESS TO PROCEED.": "Your Email Is Not Verified.  Please Verify Your Email Address to Proceed.",

    "URL COPIED TO CLIPBOARD!": "URL Copied to clipboard!",
    "ENTER LOCATION NAME / AREA NAME": "Enter Location name , Area name",
    "INTRO VIDEO": "Intro Video",
    "FULL VIDEO": "Full Video",
    "PLEASE LOG IN BEFORE REQUESTING THE TOUR SCHEDULE.": "Please Sign in before requesting the tour schedule.",
    "SEND OTP": "Send OTP",

    // NEW
    "PLEASE DRAW ANY SHAPE": "Please draw any shape",
    "ARE YOU SURE TO DELETE THIS ACCOUNT ?": "Are you sure you want to delete this account?",
    "VERIFY PHONE NUMBER": "Verify phone number",
    "COMPLETE YOUR PROFILE": "Complete your profile",
    "COMPLETE_PROFILE_TEXT": "Please complete your profile for better search results and better performance.",
    "CONTINUE": "Continue",
    "NOT NOW": "Not Now",

    // NEWLY ADDED
    "ABOUT_PAGE_TITLE": "About Amaken Plus",
    "ABOUT_META_TITLE": "About Amaken - Real Estates, Rentals, Lands and More | Amaken",
    "ABOUT_META_DESCRIPTION": "Explore real estate in Jordan on Amaken. Find apartments for sale, rentals near you, and land for sale. Discover the perfect apartment for rent today!",
    "ABOUT_KEYWORDS": "Real Estates, Rentals, Land for Sale,appartments for sale rentals near me,apartment for rent",
    "AGENCIES_PAGE_TITLE": "Real Estate Agencies",
    "AGENCIES_META_TITLE": "Top Real Estate Agencies and Brokers in Amman | Amaken",
    "AGENCIES_META_DESCRIPTION": "Discover top real estate companies, brokers, and agencies in Amman. Find the best real estate brokers and agencies to help you buy, sell, or rent properties.",
    "AGENCIES_KEYWORDS": "real estate companies,Real Estate Brokers,top real estate agencies,brokers in amman",
    "SEARCH_BY_AMAKEN_PAGE_TITLE": "Search with amaken",
    "SEARCH_BY_AMAKEN_KEYWORDS": "",
    "SEARCH_BY_AMAKEN_META_TITLE": "Find Homes for Rent and Sale | Houses for Sale Near Me | Amaken",
    "SEARCH_BY_AMAKEN_META_DESCRIPTION": "Explore homes for rent and houses for sale near you on Amaken. Discover a variety of homes for sale, including houses and properties, to find the perfect place.",

    "BLOGS": "Blogs",
    "NO BLOGS AVAILABLE": "No blogs available.",
    "CONTINUE READING" : "Continue Reading",
    "WRITTEN BY" : "Written By"
}   
